export default () => {
  // Clicking on the tab
  $('.tab').on('click', (e) => {
    e.preventDefault();

    const tab = $(e.target).data('tab');

    // Remove active classes from tabs
    $('.tab').attr('data-active', 'false');
    $('.tabs-content').removeClass('-current');

    // Add class active to the tab to activate the current tab/content
    $(e.target).attr('data-active', 'true');
    $('.tabs-content-container').find(`[data-tab-id='${tab}']`).addClass('-current');

  });
};
