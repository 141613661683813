// -----------------------------------------------------------------------------
// Hyperform
//
// Hyperform is a pure JS implementation of the HTML 5 form validation API.
//
// Docs: https://hyperform.js.org/docs
// -----------------------------------------------------------------------------
import hyperform from 'hyperform';

export default () => {

  if ($('form').length) {

    // Override email validation message for invalid email address
    $('form input[type="email"]').map((index, el) => {
      // setMessage takes three parameters (element, validator, message)
      hyperform.setMessage(el, 'typeMismatch', 'Please enter a valid email address.');
    });

    // Call hyperform
    hyperform(window, {
      classes: {
        warning: 'error-message',
        validated: '-validated',
        valid: '-valid',
        invalid: '-invalid'
      }
    });

  }
};
