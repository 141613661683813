export default () => {
  $('.training-email-url-copy').on('click', function(e) {
    const button = $(e.target);
    document.getElementById('training-email-url').select();
    document.execCommand('copy');
    button.addClass('-copied');
    setTimeout(function() {
      button.removeClass('-copied');
    }, 3000);
  });
};
