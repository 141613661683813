/* eslint-disable no-unused-vars*/
import pushMenu from 'is-mobile-nav';

export default () => {
  $('.mobile-menu-inner').pushMenu({
    menu: '.site-header-nav',
    mobileNav: '.mobile-menu',
    navButton: '.js-nav-toggle',
    pageContainer: '.page-container',
    scrollBlocker: '.js-blocker',
    nextHTML: '<a href="#" class="subopen" data-direction="next"></a>',
    prevHTML: '<li class="nav-back"><a class="subclose" data-direction="prev" href="#">Back</a></li>'
  });
};
