/* eslint-disable no-unused-vars*/
import magnificPopup from 'magnific-popup';

export default () => {
  $('.open-modal-company-selections').magnificPopup({
    type: 'inline',
    midClick: true,
    closeOnBgClick: true,
    closeBtnInside: true,
    overflowY: 'scroll'
  });
};
