// -----------------------------------------------------------------------------
// Component Includes
// -----------------------------------------------------------------------------
import siteHeader from '@components/site-header';
import formValidation from '@plugins/form-validation';
import tabs from '@components/tabs';
import mobileNav from '@components/mobile-menu';
import copyToClipboard from '@components/travel-retailer-employee-training-link';
import modalCompanySelections from '@modals/modal-company-selections';
import modalAdminInsuranceCompany from '@modals/modal-admin-insurance-company';
import modalAdminTourOperator from '@modals/modal-admin-tour-operator';
import modal from '@modals/modal';

(($) => {

  // Document Ready
  $(() => {

    // -----------------
    // High-priority JS
    // -----------------
    siteHeader();
    tabs();
    mobileNav();
    modalCompanySelections();
    modalAdminInsuranceCompany();
    modalAdminTourOperator();
    modal();

    // -----------------
    // Low-priority JS
    // -----------------
    formValidation();
    copyToClipboard();

  });

})($);
